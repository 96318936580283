document.body.addEventListener('arc_purchase', function(e){
	const { purchase } = e.detail;

	fetch('/bubblehouse/purchase', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			purchase
		})
	});
});

document.body.addEventListener('arc_user_update', function(e){
	const { user } = e.detail;

	if (user) {
		fetch('/bubblehouse/user', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				user
			})
		});
	}
});