var script = document.createElement('script');
script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=KEtKsy";
((document.getElementsByTagName('head') || [null])[0] || document.getElementsByTagName('script')[0].parentNode).appendChild(script);

var _learnq = window._learnq = window._learnq || [];

document.body.addEventListener('arc_page_view', function(e) {
	const { location, cart, user } = e.detail;

	if (location.pathname !== arc.path.cart+'checkout') {
		return;
	}

	const map = {};

	Object.keys(cart.orders).forEach(orderKey => {
		cart.orders[orderKey].products.forEach(product => {
			if (map[product.productId]) {
				map[product.productId].Quantity += product.quantity;
				map[product.productId].RowTotal += (product.price * product.quantity);
			} else {
				map[product.productId] = {
			      "SKU" : product.sku,
			      "ProductName" : product.name,
			      "Quantity" : product.quantity,
			      "ItemPrice" : product.price,
			      "RowTotal" : (product.price * product.quantity),
			      "ProductURL" : "https://www.davidscookies.com/product/"+product.sku,
			      "ImageURL" : product.files?.find(el => el.type === 'image')?.url || '',
			      "ProductCategories" : []
			    };
			}
		})
	})

	const items = Object.keys(map).map(key => map[key]);

	_learnq.push(['identify', {
		'$email': user.email || '',
		'$first_name': (user.person && user.person.firstName) || '',
		'$last_name': (user.person && user.person.lastName) || '',
	}]);

	_learnq.push(['track', 'Started Checkout', {
	  "$value" : cart.total,
	  "ItemNames" : items.map(x => x.ProductName),
	  "CheckoutURL": "https://www.davidscookies.com/cart/checkout",
	  "Items" : items,
	}]);
})