var gts = window.gts || [];
gts.push(["id", "224788"]);
gts.push(["locale", "en_US"]);
gts.push(["google_base_subaccount_id", "2665049"]);
window.gts = gts;

// Define object as configurable before Google Trusted Stores locks it.
// Need to be able to overwrite this property later to re-initiliaze the script.
Object.defineProperty(window, 'GoogleTrustedStore', {configurable: true});

setTimeout(function() {
	var script = document.createElement("script");
	script.src = "https://www.googlecommerce.com/trustedstores/api/js";
	var s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(script, s);
}, 5000);

document.body.addEventListener('arc_purchase', function(e){
	const { purchase } = e.detail;

	// Destroy old Google Trusted Store
	// TODO / Warning: Does not fully work, there is an error about duplicate scripts
	(function(){
		const dirtyDestroy = document.querySelectorAll('#gts-comm, #gts-order, #gts-c, script[src^="https://www.googlecommerce.com"], script[src^="https://www.gstatic.com/trustedstores"]');
		[].slice.call(dirtyDestroy,0).forEach(el => el.parentNode.removeChild(el));
		Object.defineProperty(window, 'GoogleTrustedStore', {value: undefined});
	}());

	// Re-init Google Trusted Store
	(function(){
		const script = document.createElement("script");
		script.src = "//www.googlecommerce.com/trustedstores/api/js";
		const s = document.getElementsByTagName("script")[0];
		s.parentNode.insertBefore(script, s);
	}());

	function googleTrustedMarkup(purchase) {
		const items = [];

		purchase.orders.forEach(order => order.products.forEach(el => {
			items.push(`
				<span class='gts-item'>
					<span class='gts-i-name'>${el.name}</span>
					<span class='gts-i-price'>${el.price}</span>
					<span class='gts-i-quantity'>${el.quantity}</span>
					<span class='gts-i-prodsearch-id'>${el.productSku}</span>
				</span>
			`);
		}));

		function formatDate(unixTimestamp) {
			const d = new Date(unixTimestamp*1000);
			return d.getUTCFullYear()+'-'+(('0'+(d.getUTCMonth()+1)).slice(-2))+'-'+(('0'+d.getUTCDate()).slice(-2));
		}

		return `
			<div id='gts-order' style='display:none' translate='no'>
				<span id='gts-o-id'>${purchase.code}</span>
				<span id='gts-o-domain'>davidscookies.com</span>
				<span id='gts-o-email'>${purchase.user?.email}</span>
				<span id='gts-o-country'>US</span>
				<span id='gts-o-currency'>USD</span>
				<span id='gts-o-total'>${purchase.total}</span>
				<span id='gts-o-discounts'>${purchase.totalDiscountAmount}</span>
				<span id='gts-o-shipping-total'>${purchase.shippingAmount}</span>
				<span id='gts-o-tax-total'>${purchase.taxAmount}</span>
				<span id='gts-o-est-ship-date'>${formatDate(purchase.orders?.[0]?.shipmentDate)}</span>
				<span id='gts-o-est-delivery-date'>${formatDate(purchase.orders?.[0]?.deliveryDate)}</span>
				<span id='gts-o-has-preorder'>N</span>
				<span id='gts-o-has-digital'>N</span>
				${items.join('')}
			</div>
		`;
	}

	var gtsContainer = document.createElement('div');
	gtsContainer.innerHTML = googleTrustedMarkup(purchase);
	document.body.appendChild(gtsContainer);
});