document.body.addEventListener('arc_page_view', (e)=> {
	const {location} = e.detail;
	var img = new Image();
	switch (location.pathname) {
		case arc.path.cart:
			img.src = "https://secure.adnxs.com/seg?add=3806498&t=2";
		break;
		case arc.path.cart+"complete":
			img.src = "https://secure.adnxs.com/px?id=625295&t=2";
		break;
		default:
			img.src = "https://secure.adnxs.com/seg?add=3806502&t=2";
		break;
	}
})