// Heap Analytics Setup
const setupHeap = (appId) => {
  window.heap = window.heap || [];
  heap.load = function (e, t) {
    window.heap.appid = e;
    window.heap.config = t = t || {};
    var r = document.createElement("script");
    r.async = true;
    r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
    var a = document.getElementsByTagName("script")[0];
    a.parentNode.insertBefore(r, a);
    var n = function (e) {
      return function () {
        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
      };
    };
    var p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"];
    for (var o = 0; o < p.length; o++) heap[p[o]] = n(p[o]);
  };
  heap.load(appId);
}

setupHeap("2279223036");