import url from '../url';

document.body.addEventListener('arc_purchase', function(e){
	const { purchase } = e.detail;

	purchase.orders.forEach(function(order) {
		const query = {
			'app_key': '6TMQIWunoZELYLkQK1wvazy444bVvch91zO40Jwi',
			'order_id': order.purchaseOrderCode,
			'order_amount': order.subTotal,
			'order_currency': 'USD',
		};
		const img = new Image();
		img.src = "//api.yotpo.com/conversion_tracking.gif"+url.stringify(query);
	});
});