(function(){
	window.gtagID = 'G-2YB4XN44EC';

	const script = document.createElement("script");
	script.src = "https://www.googletagmanager.com/gtag/js?id="+window.gtagID;
	const s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(script, s);

	window.dataLayer = window.dataLayer || [];

	window.gtag = function (){
		dataLayer.push(arguments);
	}
	
	gtag('js', new Date());

	// note: to disable automatic pageview, use this instead:
	gtag('config', window.gtagID, { 'send_page_view': false });
}());

// Pageview Tracking
document.body.addEventListener('arc_page_view', (e)=> {
	const { location } = e.detail;

	const eventData = {
		page_title: document.title,
		page_location: location.href,
	};

	gtag('event', 'page_view', eventData);
})
