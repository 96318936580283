window.ju_num="103BDBCC-9D4F-41DF-BDB4-0EC915183E28";
window.asset_host='//cdn.justuno.com/';
(function(i,s,o,g,r,a,m){i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script',asset_host+'vck.js','juapp');

document.body.addEventListener('arc_purchase', function(e){
	const { purchase } = e.detail;

	window.juapp=window.juapp||function(){(window.juapp.q=window.juapp.q||[]).push(arguments)}

	purchase.orders.forEach(function(order) {
		juapp( 'order', order.purchaseOrderCode, {
			total: order.total,
			subtotal: order.subTotal,
			tax: order.taxAmount,
			shipping: order.shippingAmount,
			currency:'USD'
		});

		order.products.forEach(function(item) {
			juapp( 'orderItem', item.productId, {
				name: item.name,
				quantity: item.quantity,
				price: item.price
			});
		});
	});
});