// This is a quality-of-life addition to expedite removing properties from the querystring,
// rather than pushing that frequent object modification down to where stringify is invoked
export function removeNulls(obj) {
	return Object.entries(obj).reduce(
		(acc, [key, value]) => {
			if (value === null) {
				delete acc[key];
			}
			return acc;
		},
		{ ...obj }
	);
}

export function stringify(obj) {
	return Object.entries(removeNulls(obj))
		.map(([key, value]) => {
			return (
				encodeURIComponent(key) + '=' + encodeURIComponent(value as string)
			);
		})
		.reduce((acc, cur, i) => acc + (i ? '&' : '?') + cur, '');
}

export function parse(qs) {
	return qs
		.slice(1)
		.split('&')
		.map((p) => p.split('=').map(decodeURIComponent))
		.reduce((obj, [key, value]) => {
			if (key) {
				obj[key] = value;
			}
			return obj;
		}, {});
}

export function push(pathname, query = {}, hash = null, scrollToTop = false) {
	const search = stringify(query);
	window.history.pushState({}, '', pathname + search + (hash || ''));
	dispatch(scrollToTop);
}

export function replace(pathname, query = {}, hash = null, scrollToTop = false) {
	const search = stringify(query);
	window.history.replaceState({}, '', pathname + search + (hash || ''));
	dispatch(scrollToTop);
}

const cbs = [];

export function dispatch(scrollToTop) {
	const l = window.location;

	cbs.forEach((cb) =>
		cb(
			{
				// standard location values
				hash: l.hash,
				host: l.host,
				hostname: l.hostname,
				href: l.href,
				origin: l.origin,
				pathname: l.pathname,
				port: l.port,
				protocol: l.protocol,
				search: l.search,
				// parsed querystring for convenience
				query: parse(l.search),
			},
			scrollToTop
		)
	);
}

export function subscribe(cb) {
	cbs.push(cb);
}

export default {
	parse,
	stringify,
	push,
	replace,
	dispatch,
	subscribe,
};
