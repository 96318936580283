import request from 'arcdynamic-request';

function getEventData(cart) {
	let coupon = '';

	if (cart.discounts && cart.discounts.length) {
		coupon = cart.discounts[0].name;
	}

	const items = [];
	Object.values(cart.orders).forEach(order => {
		order.products.forEach(x => {
			items.push({
				item_id: x.sku,
				item_name: x.name,
				quantity: x.quantity,
				price: x.price,
			})
		})

		if (!coupon && order.discounts && order.discounts.length) {
			coupon = order.discounts[0].name;
		} 
	})

	return {
		currency: 'USD',
		value: cart.total,
		coupon,
		items,
	}
}

document.body.addEventListener('arc_page_view', function(e){
	const { location, cart, user } = e.detail;

	if (location.pathname === arc.path.cart+'checkout' && cart) {
		gtag('event', 'begin_checkout', getEventData(cart));
	}

	else if (location.pathname === arc.path.cart+'payment' && cart) {
		gtag('event', 'checkout_progress', getEventData(cart));
	}
})

document.body.addEventListener('arc_add_to_cart', function(e){
	const { product, quantity } = e.detail;

	if (product) {
		gtag('event', 'add_to_cart', {
			currency: 'USD',
			value: quantity * product.price,
			items: [
				{
					item_id: product.sku,
					item_name: product.name,
					quantity: quantity,
					price: product.price,
				}
			],
		});
	}
})

document.body.addEventListener('arc_purchase', function(e){
	const { purchase } = e.detail;
	const items = [];

	purchase.orders.forEach(function(el) {
		var purchaseOrderCode = el.purchaseOrderCode;

		el.products.forEach(function(el) {
			items.push({
				item_id: el.productSku,
				item_name: el.name,
				quantity: el.quantity,
				price: el.price,
			})
		});

		var coupon = '';
		if (Array.isArray(el.tags)) {
			el.tags.some(tag => {
				if(tag.code === 'coupon') {
					coupon = tag.valueCode;
					return true;
				}
			});
		}

		const eventData = {
			"transaction_id": purchaseOrderCode,
			"affiliation": "David's Cookies",
			"value": el.total,
			"currency": "USD",
			"tax": el.taxAmount,
			"shipping": el.shippingAmount,
			"coupon": coupon, // todo: is this the right place for coupon?
			"items": items,
		};

		gtag('event', 'purchase', eventData);

		try {
			// also send eventData to an arcimedes table to quantify how much analytics is being blocked
			request(arc.path.arcimedes, {
				service: 'arcimedes',
				action: 'open.datasource.table.Data.updateByTableCode',
				params: ['PURCHASES', {
					value: [
						{
							TRANSACTION_ID: purchaseOrderCode,
							VALUE: el.total,
							GA_EVENT_DATA: JSON.stringify(eventData),
							// an alternative but asynchronous detection method would be
							// to attempt a POST to https://analytics.google.com/g/collect?v=2
							GA_BLOCKED: typeof window.dataLayer.push.prototype === 'undefined' ? 1 : 0,
						},
					],
				}],
			})
			.then(res =>{
				console.log(res);
			})
		} catch (e) {
			console.error(e);
		}
	});

	// Google Adwords tracking
	var img = new Image();
	img.src = `//www.googleadservices.com/pagead/conversion/1069236830/?value=${purchase.total}&currency_code=USD&label=tBWHCOT84gMQ3oTt_QM&guid=ON&script=0`;
});