// Facebook Pixel
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');

fbq('init', '759231767804252');

document.body.addEventListener('arc_add_to_cart', function(e) {
	const { id, quantity } = e.detail;
	fbq('track', 'AddToCart', {contents: [{id, quantity}]}); // todo: should we use the product.sku as the ID?
});

document.body.addEventListener('arc_page_view', function(e) {
	const { location, cart } = e.detail;

	// In addition to checking that the page is the checkout page,
	if (location.pathname === arc.path.cart+'checkout' && cart) {
		fbq('track', 'InitiateCheckout', {
			value: cart.total,
			currency: 'USD',
		});
	}
})

document.body.addEventListener('arc_purchase', function(e){
	const { purchase } = e.detail;

	const contents = [];

	purchase.orders.forEach(function(el) {
		el.products.forEach(({productId, quantity}) => contents.push({id: productId, quantity}));
	});

	fbq('track', 'Purchase', {
		value: purchase.total,
		currency: 'USD',
		contents,
	});	
});